/**
 * タブ切り替え用のjsです。
 * 選択したtabのdata属性値と一致していdata属性値の要素を表示させます。
 * → data-tabを選択して一致しているdata-tabboxの要素を表示させています。
 */

export function tabChange() {
  const tabTrigger = document.querySelectorAll('.js-tabTrigger');
  const tabList = Array.prototype.slice.call(tabTrigger, 0);
  const tabBoxTrigger = document.querySelectorAll('.js-tabBox');
  const tabBoxList = Array.prototype.slice.call(tabBoxTrigger, 0);

  /** tab change */
  tabList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      // newsのメニューリストから全てのis-selectedを削除
      for (let i = 0; i < tabList.length; i++) {
        tabList[i].classList.remove('is-selected');
      }
      targetBox.classList.add('is-selected');

      // get data-tab="〇〇"
      const tabData = targetBox.dataset.tab;

      // 選択したメガメニューに対応したメニューにclass付与で表示
      tabBoxJudge(tabData);
    });
  });

  // 選択したタブと一致したメガメニューの判別用の関数
  function tabBoxJudge(data) {
    for (let i = 0; i < tabBoxList.length; i++) {
      tabBoxList[i].classList.remove('is-view');
    }

    tabBoxList.forEach((targetBox) => {
      const tabBoxData = targetBox.dataset.tabbox;

      if (data == tabBoxData) {
        targetBox.classList.add('is-view');
      }
    });
  }
}
