export function modalTeacher() {
  const modalTeacherTrigger = document.querySelectorAll('.js-modalTeacherTrigger');
  const modalTeacherTriggerList = Array.prototype.slice.call(modalTeacherTrigger, 0);

  const bodyEle = document.querySelector('body');
  const modalTeacher = document.getElementById('modalTeacher');
  const modalTeacherOverlay = document.querySelector('.js-modalTeacherOverlay');
  const modalTeacherEle = document.querySelector('.js-modalTeacherEle');
  const modalTeacherClose = document.querySelector('.js-modalTeacherClose');
  let modalName01 = '';
  let modalName02 = '';
  let modalTitle = '';
  let modalText = '';
  let modalTeacherImg = '';
  let modalArtImg01 = '';
  let modalArtImg02 = '';
  let modalArtImg03 = '';
  let modalCap01 = '';
  let modalCap02 = '';
  let modalCap03 = '';
  let modalData = '';
  let modalProfLink = '';

  // 各モーダルに反映するテキスト用配列
  const modalDataObj = {
    mediaarts01: {
      name01: '舞台芸術コース　舞台制作専攻　客員教授',
      name02: '竹内 一秀',
      title: 'プロ意識が芽生える教えを',
      text: '演劇・舞踏の照明デザインを中心に、舞台監督や美術デザインなども行う。劇場建設にも携わり、小劇場では「ロミオとジュリエット」をはじめとした舞台の演出も手掛け、劇場建設のコンサルティング会社に所属している。「舞台はチームで作り上げる芸術」と語り、実践を通した授業で学生たちのプロ意識が芽生えるよう指導している。',
      teacherImg: '/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_takeuchi.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_takeuchi_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_takeuchi_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">滋賀県びわ湖ホール計画</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">滋賀県びわ湖ホール計画（客席から舞台）</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/339.html',
    },
    mediaarts02: {
      name01: '舞台芸術コース　⾝体表現　教授',
      name02: '加納 ⻯',
      title: '演技は人にしかできない芸術',
      text: '1975年にアイドル歌手としてデビューし、俳優として精力的に活動。代表作である刑事ドラマ「西部警察」をはじめとし、映画や舞台、ミュージカルにも挑戦。40年以上も第一線で活躍を続けている。「演技は人と人とのコミュニケーション、アナログの力で成り立つもので人間にしかできない芸術」と語り、授業でも学生たち一人ひとりと向き合っている。',
      teacherImg: '/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_kano.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_kano_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/mediaarts/theatricalarts/index/theatricalarts_teacher_kano_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">西部警察　&copy;石原プロモーション</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">西部警察　&copy;石原プロモーション</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/400.html',
    },
    mediaarts03: {
      name01: '声優コース　教授',
      name02: '渡辺 菜⽣⼦',
      title: '長年国民的アニメで活躍する<br>ベテラン声優',
      text: '国民的アニメ「ちびまる子ちゃん」のクラスメイト「たまちゃん」役や「ドラゴンボール」シリーズの主人公・孫悟空の妻「チチ」役などを担当するベテラン声優。「声優は声質よりも声力が大事」という想いから、喋り方のメリハリや情緒を表現する技術力を高める指導を心掛ける。',
      teacherImg: '/assets/img/departments/mediaarts/voiceactor/index/voiceactor_teacher_watanabe.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/voiceactor/index/voiceactor_teacher_watanabe_achievement01.jpg" alt="" />',
      teacherArtImg02: '',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">「ちびまる子ちゃん」のたまちゃん役を1990年から担当<br>&copy;さくらプロダクション／日本アニメーション</span>',
      teacherArtCap02: '',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/477.html',
    },
    mediaarts04: {
      name01: 'ポピュラーダンスコース　客員准教授',
      name02: '松村 武司',
      title: '老若男女が楽しめる「ダンス」の表現者',
      text: 'テーマパークやミュージカルなど幅広いダンサー経験を積み、多くの有名アーティストのバックアップダンサーや振付師も手がける。2006年には、アジア競技大会のオープニングセレモニーで、日本代表としてパフォーマンス。「ダンスは老若男女が楽しめる芸術」と語り、現在もダンサーとして活動しながら、経験を生かした実践的な授業を展開している。',
      teacherImg: '/assets/img/departments/mediaarts/populardance/index/populardance_teacher_matsumura.jpg',
      teacherArtImg01: '<iframe width="560" height="315" src="https://www.youtube.com/embed/aAfkOrjfEgg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
      teacherArtImg02: '',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">DOHA 2006 ASIAN GAMES OPENING CEREMONY<br>2006年のアジア競技大会 オープニングセレモニーで日本代表としてパフォーマンス。</span>',
      teacherArtCap02: '',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/266.html',
    },
    mediaarts05: {
      name01: 'ポピュラー音楽コース　教授',
      name02: '中出 安子',
      title: '音楽家として誇れる指導を',
      text: '神戸女子学院大学音楽部ピアノ科を首席で卒業。音楽制作会社に20年以上も勤務し、ピアノ制作以外にもイベント企画やコンサート演出、編曲、タレントの育成など活躍の場を広げる。「音楽は一生続く学び」と語り、これまでの経験を生かしながら技術だけでなく、人としても成長できるような指導をしている。',
      teacherImg: '/assets/img/departments/mediaarts/popularmusic/index/popularmusic_teacher_nakade.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/popularmusic/index/popularmusic_teacher_nakade_achievement01.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/mediaarts/popularmusic/index/popularmusic_teacher_nakade_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">プロデュースするジャズコーラスグループ、サテンドールのアルバム</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">ラヴァーズコンチェルトのクリスマスナイト</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/313.html',
    },
    mediaarts06: {
      name01: 'メディアコース　ライティング・編集・取材・ウェブ専攻　教授',
      name02: '松尾 理也',
      title: 'メディアの未来を見据え、人材教育に尽力',
      text: '京都大学大学院博士課程修了、博士（教育学）。全国紙の記者としてニューヨーク特派員などを歴任した。専門はメディア史で「大阪時事新報の研究」（創元社）、「ルート66をゆく」（新潮新書）など著書多数。メディアの未来を見据え、報道現場の知見を生かして人材育成に力を入れる。',
      teacherImg: '/assets/img/departments/mediaarts/media/index/media_teacher_matsuo.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/media/index/media_teacher_matsuo_achievement01.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/mediaarts/media/index/media_teacher_matsuo_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">ルート66をゆく</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">大阪時事新報の研究</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/273.html',
    },
    mediaarts07: {
      name01: 'メディアコース　広告専攻　客員教授',
      name02: '今村 荘三',
      title: '個性を見つけ、伸ばす楽しい授業を',
      text: 'CMプランナー、広告クリエイター。お笑いへの造形も深く、漫才や芸人に関する著書も多数執筆。大学卒業後、博報堂に入社し幅広い分野のCM制作を手掛けて数々の賞を受賞した経歴を持つ。メディアコースでは自身の経験を生かしながらも、「発想する楽しさを教えたい」と学生たちの個性を見つけ、楽しみながら伸ばせるような授業を心がけているという。',
      teacherImg: '/assets/img/departments/mediaarts/media/index/media_teacher_imamura.jpg',
      teacherArtImg01: '',
      teacherArtImg02: '',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">実績のタイトルが入ります</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">実績のタイトルが入ります</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/428.html',
    },
    designarts01: {
      name01: 'グラフィックデザイン・イラストレーションコース<br />グラフィックデザイン専攻　教授',
      name02: '松井 桂三',
      title: '「上質なデザイン」を伝える世界的アートディレクター',
      text: 'アップルのパソコン「Mac」の基本パッケージデザインシステム構築に携わり、国内でも政府広報のシンボルマーク制作やロート製薬「肌研ハダラボ」の企画、シンボルロゴ、パッケージなどを手掛けた世界的アートディレクター。基礎的な学びだけではなく、業界の第一人者たちと対談するような特別授業で若い世代に「上質なデザインとは何か」を伝えている。',
      teacherImg: '/assets/img/departments/designarts/index/departments_design_index_message_modal.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/mediaarts/media/index/media_teacher_imamura_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/index/media_teacher_imamura_achievement03.png" alt="" />',
      teacherArtImg03: '<img src="/assets/img/departments/mediaarts/media/index/media_teacher_imamura_achievement02.jpg" alt="" />',
      teacherArtCap01: '<span class="u-tac u-display--block">政府広報ロゴマーク</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">サントリーミュージアム「美女100年」展ポスター</span>',
      teacherArtCap03: '<span class="u-tac u-display--block">「肌研ハダラボ」ロゴ&amp;パッケージ群/ロート製薬</span>',
      modalProf: '/guide/financial/teacher/274.html',
    },
    designarts02: {
      name01: 'グラフィックデザイン・イラストレーションコース',
      name02: 'イラストレーション専攻　教授　立本 倫子',
      title: 'さまざまな画材に挑戦し新しい自分に出合う',
      text: '絵本をはじめ、小学校の教科書の表紙や辞典など教育分野でのイラストを多く手がける他、広告、CM、ポスター、パッケージ、キャラクターデザイン、映像、クラフト、雑貨、子ども服といった幅広いジャンルのイラストを手掛ける。子どものころから絵本を描きたいという夢を持っていたため、学生たちにも夢や自分の新しい可能性が見つけられるようさまざまなジャンルに挑戦させている。',
      teacherImg: '/assets/img/departments/designarts/graphic_illust/graphic_illust_teacher_tachimoto.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_achievement03.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_achievement04.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">『アニーとパパのハロウィン』文溪堂</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">『おひさまとかくれんぼ』（教育画劇）本文より</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/334.html',
    },
    designarts03: {
      name01: '空間演出デザインコース　兼担',
      name02: '谷尻 誠',
      title: '昨日より今日、クオリティを追求し続ける建築家',
      text: '建築家・起業家。2000年建築設計事務所「SUPPOSE DESIGN OFFICE」設立。住宅、商業空間、会場構成、ランドスケープ、プロダクト、インスタレーションなど仕事の範囲は多岐にわたる。広島・東京の2ヵ所を拠点に、インテリアから住宅、複合施設など国内外で多数のプロジェクトを手がける。近作に「関東マツダ目黒碑文谷店」「BOOK AND BED TOKYO」等。著書に「談談妄想」(ハースト婦人画報社)「1000%の建築」(エクスナレッジ)がある。',
      teacherImg: '/assets/img/departments/designarts/spacedirection/spacedirection_teacher_tanijiri.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/spacedirection/spacedirection_achievement03.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/spacedirection/spacedirection_achievement05.jpg" alt="" />',
      teacherArtImg03: '<img src="/assets/img/departments/designarts/spacedirection/spacedirection_achievement04.png" alt="" />',
      teacherArtCap01: '<span class="u-tac u-display--block">ONOMICHI U2</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">ONOMICHI U2</span>',
      teacherArtCap03: '<span class="u-tac u-display--block">Ovolo Nishi<br><br><span class="c-textType03--medium">施工<br>PLY Nikias Diamond<br>Nishi Residential Building<br><br>Design Team<br>Fender Katsalidis Architects<br>Oculus Landscape Architecture and Urban Design<br>March Studio<br>Craig Tan Architects<br>Don Cameron<br>Ken Neale<br>Broached Commissions<br>Design Office</span></span>',
      modalProf: '/guide/financial/teacher/330.html',
    },
    designarts04: {
      name01: 'アートサイエンスコース　教授',
      name02: '木村 幸司',
      title: '最新技術を掛け合わせて人の「体験」を作る',
      text: 'アートサイエンスコースは表現が多岐に渡るため、さまざまな領域を学ばなければなりません。作りたいものを実現するには、手段を問わずに貪欲に学ぶ気概と、細部までこだわりを持って打ち込む探求心が重要な鍵となります。ものづくりを楽しみながらとことん突き詰め、誰もが驚く「体験」を創り出しましょう。',
      teacherImg: '/assets/img/departments/designarts/artscience/artscience_teacher_kimura.jpg',
      teacherArtImg01: '<iframe src="https://player.vimeo.com/video/222513940?h=c0437097b2&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>',
      teacherArtImg02: '<iframe src="https://player.vimeo.com/video/739162611?h=33278f9b1c" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">lumen</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">ray</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/386.html',
    },
    designarts05: {
      name01: 'アニメーション・デジタルデザイン・ゲームコース',
      name02: '講師 加藤 亮平',
      title: 'WEBやデザインの基礎を押さえて<br>積極的に学ぶ、有意義な2年間を',
      text: 'サイトの模写や実制作を多く取り入れた授業で、コーディングの仕組みや原理、デザインの基礎が身に付けられます。WEB制作を通して磨けるデザイン的感覚は、ゲーム開発やアニメ制作などでも応用でき、あらゆる場面で役立つ力になります。アドバイスやサポートは惜しみません。2年という限られた学びの時間を意欲的に楽しんでください。',
      teacherImg: '/assets/img/departments/designarts/adg/adg_teacher_kato.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/adg/adg_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/adg/adg_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">GATSBY</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">SHIMANO FISHING</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/404.html',
    },
    designarts06: {
      name01: 'キャラクター・マンガ・フィギュアコース',
      name02: 'キャラクター・マンガ専攻　准教授　ねもと 章子',
      title: '名作マンガを数々発表<br>デジタル作画技術にも注目',
      text: '白泉社のアテナ新人大賞新人賞を受賞し、少女誌で漫画家デビュー。代表作に「ヘルメスの翼のもとに」（レートルシリーズ）「シャーロック・ホームズ〜緋色の研究」などがある。早期からデジタルによる作画を取り入れ、小学館 刊「めざせ漫画家 PCでまんがを描こう！」ほかに寄稿。マンガ制作ソフト「CLIP STUDIO PAINT」でのデジタル作画の指導も行う。',
      teacherImg: '/assets/img/departments/designarts/character_comics_figure/character_comics_figure_teacher_nemoto.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement01.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">エルフ・メイドン</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">イマココニイル… 〜石巻に在った優しい試み〜</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/301.html',
    },
    designarts07: {
      name01: 'キャラクター・マンガ・フィギュアコース',
      name02: 'フィギュア専攻　教授　藤田 茂敏',
      title: 'フィギュア教育の<br>第一人者',
      text: 'フィギュア、ノベルティグッズ、ペットボトルなどを手掛ける原型師およびフィニッシャー。さまざまな学校でフィギュア関連学科の立ち上げやカリキュラム編成に携わるなど、「学問としてのフィギュア」に注力。著書にグラフィック社『フィギュアの作りかた』（全4巻）、新紀元社『フィギュアの教科書 レジンキット&塗装入門編 How to build GARAGE KIT』など。',
      teacherImg: '/assets/img/departments/designarts/character_comics_figure/character_comics_figure_teacher_fujita.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement05.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement06.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">Girl01_ミルカ（フィニッシング）</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">Girl02_楓</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/284.html',
    },
    designarts08: {
      name01: '絵画・版画コース　絵画専攻　特任准教授',
      name02: '北城 貴子',
      title: '学生たちの気持ちを第一に、作家経験を生かして指導',
      text: '学生たちの「絵を描きたい」という純粋な気持ちを大切に、自身の個展など、最前線で感じているノウハウや感覚を学生に伝えている。創作活動について「作品は受け手のことも考えて表現する」と語り「自分が作品を描いたときの気持ちなども伝えるようにしている」と、作家経験を生かして指導している。',
      teacherImg: '/assets/img/departments/designarts/paint_artprint/paint_artprint_teacher_hojo.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">melts with light no.3</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">Prismatic color</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/282.html',
    },
    designarts09: {
      name01: '絵画・版画コース　版画専攻　特任准教授',
      name02: '大舩 光洋',
      title: '自己満足ではなく、誰かに影響を与える作品を',
      text: '浪速短期大学（現大阪芸術大学短期大学部）在学中に版画の魅力を学び、独特な表現に引かれ版画の道に進む。現在も個展やグループ展などで積極的に作品を発表しており、そうした経験から学生たちには「自己満足ではなく、誰かに影響を与える作品を生み出してほしい」と指導している。版画コースでは、制作技術を磨きながら学生たちの個性を生かす作品づくりに取り組んでいる。',
      teacherImg: '/assets/img/departments/designarts/paint_artprint/paint_artprint_teacher_ofune.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_achievement05.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_achievement06.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">あの空の想い L-1</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">あの空の想い L-2</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/414.html',
    },
    designarts10: {
      name01: '工芸・立体デザインコース　教授',
      name02: '堀野 利久',
      title: 'あらゆる表現で作品を生み出す変幻自在の陶芸家',
      text: '1976年、浪速短期大学（現大阪芸術大学短期大学部）デザイン美術科工業工芸デザイン専攻卒業。陶芸家として、大阪、京都、兵庫、東京を中心に個展やグループ展で数多くの作品を発表している。毎年、技法や表現方法を変えて多種多様な作品づくりに挑戦。「固定概念にとらわれない自由な発想」を大切にしており、大阪芸短でも造形表現の可能性を広げる授業を展開している。',
      teacherImg: '/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_teacher_horino.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">直</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">合　W-Ⅲ</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/277.html',
    },
    designarts11: {
      name01: 'キャラクター・マンガ・フィギュアコース',
      name02: 'キャラクター・マンガ専攻 准教授 林　日出夫',
      title: 'プロの作家としての知見を<br>生かした授業',
      text: '取材記者としてキャリアをスタートさせ、1993年に青年漫画原作のコンテストで入選。漫画原作者として、さまざまな作品を世に送り出す。授業では、自身の経験を生かしてリアリティのあるキャラクター造形やストーリーの作り方を教えている。生徒の個性を伸ばしながらも、漫画の歴史、近年の傾向を踏まえプロデビューをサポートする。',
      teacherImg: '/assets/img/departments/designarts/character_comics_figure/character_comics_figure_teacher_hayashi.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement03.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_achievement04.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">卑怯者の兵器</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">完全保存版 野球マンガ大解剖</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/291.html',
    },
    designarts12: {
      name01: 'グラフィックデザイン・イラストレーションコース',
      name02: '客員教授 澁谷 克彦',
      title: '受賞歴が豊富な<br>誰もが認めるクリエーター',
      text: 'アートディレクター/グラフィックデザイナー。1981年東京藝術大学卒業。資生堂宣伝部を経て2017年に独立。「clé de peau beaute」「SHISEIDO」などグローバルブランド全体のディレクション、「花椿」のアートディレクションなど資生堂のクリエイティブをはじめ、「ISSEY MIYAKE」「TSUMORI CHISATO」など多数。2019年「ヒロシマ・アピールズ」ポスター制作。女子美術大学客員教授。亀倉雄策賞、東京ADC会員賞、ADC賞、東京TDC金賞、JAGDA新人賞、JAGDA賞、NYADC特別賞他。東京アートディレクターズクラブ会員。',
      teacherImg: '/assets/img/departments/designarts/graphic_illust/graphic_illust_teacher_shibuya.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_shibuya_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_shibuya_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">HIR0SHIMA  APPEALS/JAGDA 2019</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">企業イメージポスター/資生堂</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/359.html',
    },
    designarts13: {
      name01: 'グラフィックデザイン・イラストレーションコース',
      name02: '客員教授 左合 ひとみ',
      title: '地域や平和をテーマに活動する<br />社会派クリエイター',
      text: '東京藝術大学美術学部卒業後、パルコ広告制作局などを経て左合ひとみデザイン室設立。広島県の「藤い屋」「古今果」、香川県の「小豆島ヘルシーランド」、岐阜県の「御菓子つちや」など地域産業のブランディングを多く手がける。JAGDA新人賞、NYADC銀賞、日本パッケージデザイン大賞特別賞、全国カレンダー展通商産業大臣賞、ドイツカレンダー展銅賞、TOPAWARD ASIAなど国内外の賞を受賞。',
      teacherImg: '/assets/img/departments/designarts/graphic_illust/graphic_illust_teacher_sagou.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_sagou_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_sagou_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">「藤い屋もみじまんじゅう」お菓子のパッケージ<br>伝統的な土産菓子を現代的にリニューアルした。</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">「Rebonjour -The Planet Wishes for Peace」<br class="sp">個展用ポスター<br>社会的なテーマに取り組んだシリーズの一枚で、<br>広島・長崎の原爆投下に対する平和のメッセージを<br class="sp">こめている。</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/370.html',
    },
    designarts14: {
      name01: 'キャラクター・マンガ・フィギュアコース',
      name02: '客員准教授 凸ノ高秀',
      title: '数々のヒット作を生み出す<br>実力派漫画家',
      text: '漫画家/イラストレーター。2007年大阪芸術大学デザイン学科卒業。単行本「童貞骨牌（2013年）」「蝉の恋（2015年）」他、漫画アクション、ジャンプSQ、少年ジャンプ等で読み切り作品を多数発表。2018年7月より少年ジャンプにて「アリスと太陽」を連載。また「世界一長い1コマ漫画」制作でギネス記録取得（2018年）。となりのヤングジャンプ「she is beautiful」を連載中。',
      teacherImg: '/assets/img/departments/designarts/character_comics_figure/character_comics_figure_teacher_totsuno.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_totsuno_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/character_comics_figure/character_comics_figure_totsuno_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">She is beautiful /2022年<br>原作：江坂 純 / となりのヤングジャンプ連載中(集英社)</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">アリスと太陽_週刊少年ジャンプ(集英社)</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/321.html',
    },
    designarts15: {
      name01: '工芸・立体デザインコース',
      name02: '教授 柴田 明美',
      title: '自分の感性を作品に<br>自由な発想を楽しんでほしい',
      text: '色・柄がさまざまな布を組み合わせて表現するアメリカンパッチワークでは、絵の具とは違った表現ができて面白いです。授業では「のびのび、自由に」を合言葉に、個性を生かしたものづくりが楽しめるようサポートします。パッチワークを本格的に学べる学校は珍しく、あまり類がありません。ぜひ大阪芸短で挑戦してみてください。',
      teacherImg: '/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_teacher_shibata.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_achievement05.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_achievement06.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">かわいいハウス</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">小鳥と小さなポーチ</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/360.html',
    },
    designarts16: {
      name01: '工芸・立体デザインコース',
      name02: '特任教授 川島　慶樹',
      title: '植物のアートで<br>空間をデザインする彫刻家',
      text: '彫刻家■個展/2018年アートコートギャラリー(大阪)、2019 年Clysa(Spain)2021 ACG Villa Kyoto(京都)他■グループ展/2018年「ニューウェーヴ 現代美術の80 年代」(国立国際美術館)、2021年関西の80年代（兵庫県立美術館)他■野外彫刻/2003年 EL PALAU FALGUERA エントランス(Spain)、2020年 ANA インターコンチネンタル石垣リゾート エントランス(石垣島)、2020 年島津製作所 基盤技術研究所（けいはんな）中庭（京都)他多数■屋内彫刻/2014年 汐留住友ビル B1 エントランス(東京)、2016年 グランドプリンスホテル新高輪 エントランス(東京) 他■パブリックコレクション/和歌山県立近代美術館、サントリー美術館。',
      teacherImg: '/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_teacher_kawashima.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_kawashima_achievement01.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/crafts_sculpture/crafts_sculpture_kawashima_achievement02.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">Twiggy &amp; Margarita<br>ACG Villa Kyoto/Kyoto  2021<br>stainless steel, glass, LED</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">ANA intercontinental Ishigaki resort 2019<br>エントランス車寄せ水盤<br>h2.5m x φ12m</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/397.html',
    },
    designarts17: {
      name01: '絵画・版画コース',
      name02: '特任准教授 石橋　志郎',
      title: '柔らかな表現で<br>人々の心をつかむ日本画家',
      text: '日本画家。2007年京都市立芸術大学大学院修士課程美術研究科絵画専攻日本画領域修了。■個展/2021年「灰色と光」 ＋1art(大阪)、2019 「絵画と輝度」 ギャラリーあしやシューレ (兵庫)■企画展・グループ展 /2022年「Kyoto Art for Tomorrow 2022 -京都府新鋭選抜展–」/京都文化博物館、2021年「ベリーマキコ・石橋志郎　ふたりの視点」/今治市大三島美術館■受賞歴 /2021年令和3年度京都市芸術新人賞、2021年「京都日本画新展2021大賞。',
      teacherImg: '/assets/img/departments/designarts/paint_artprint/paint_artprint_teacher_ishibashi.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_ishibashi_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/paint_artprint/paint_artprint_ishibashi_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">Tone<br>116.7 × 162.0cm / 2022<br>紙本彩色</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">Atmosphere<br>70.0 × 70.0cm / 2022<br>紙本彩色</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/438.html',
    },
    designarts18: {
      name01: 'グラフィックデザイン・イラストレーションコース',
      name02: '特任教授 福田 利之',
      title: 'プレーヤー意識を持って<br>社会で活躍しよう',
      text: '近年急速に盛り上がりを見せている「推し活」。手軽にコンテンツを享受できる時代になりましたが、これからは皆さんが発信する側、つまり「推される側」になるつもりで学びに取り組んでもらいたいと思っています。芸短で発想力と描画技法を磨き、社会で活躍できる一流プレーヤーへと一緒に成長していきましょう。',
      teacherImg: '/assets/img/departments/designarts/graphic_illust/graphic_illust_teacher_fukuda.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_achievement05.png" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/graphic_illust/graphic_illust_achievement06.png" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">大丈夫じゃないのに大丈夫なふりをした</span>',
      teacherArtCap02: '<span class="u-tac u-display--block">クララとお日さま</span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/286.html',
    },
    designarts19: {
      name01: 'アニメーション・デジタルデザイン・ゲームコース',
      name02: '講師 細尾 正行',
      title: 'ロゴやマークに定評のある<br />クリエイティブディレクター',
      text: '印刷会社、文具メーカー、公共系Web制作会社を経て2008年に独立、フリーランスとして活動。その後2016年に株式会社スピッカートを設立。クリエイティブディレクターとしてプロジェクト全体の統括を行う傍ら、デザイン、イラストなどを制作する。シロクマグッズが好きなシロクマコレクター。グッドデザイン賞2020 金賞、日本タイポグラフィ年鑑2023ロゴタイプ・シンボルマーク部門 ベストワーク賞受賞。',
      teacherImg: '/assets/img/departments/designarts/adg/adg_teacher_hosoo.jpg',
      teacherArtImg01: '<img src="/assets/img/departments/designarts/adg/adg_hosoo_achievement01.jpg" alt="" />',
      teacherArtImg02: '<img src="/assets/img/departments/designarts/adg/adg_hosoo_achievement02.jpg" alt="" />',
      teacherArtImg03: '',
      teacherArtCap01: '<span class="u-tac u-display--block">SOOOPロゴ/日本タイポグラフィ年鑑2023ロゴタイプ・シンボルマーク部門 ベストワーク賞受賞</span>',
      teacherArtCap02: '<span class="u-tac u-display--block"><a href="https://son-okada.com/concept/" class="c-linkText" target="_blank">SoN Webサイト</a></span>',
      teacherArtCap03: '',
      modalProf: '/guide/financial/teacher/535.html',
    },
  };

  modalTeacherTriggerList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      const dataId = targetBox.getAttribute('id');

      // 配列内のテキストを各変数に格納
      Object.keys(modalDataObj).forEach((keyX) => {
        if (dataId == keyX) {
          Object.keys(modalDataObj[keyX]).forEach((keyY) => {
            if (keyY == 'name01') {
              modalName01 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'name02') {
              modalName02 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'title') {
              modalTitle = modalDataObj[keyX][keyY];
            }
            if (keyY == 'text') {
              modalText = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherImg') {
              modalTeacherImg = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtImg01') {
              modalArtImg01 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtImg02') {
              modalArtImg02 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtImg03') {
              modalArtImg03 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtCap01') {
              modalCap01 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtCap02') {
              modalCap02 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'teacherArtCap03') {
              modalCap03 = modalDataObj[keyX][keyY];
            }
            if (keyY == 'modalProf') {
              modalProfLink = modalDataObj[keyX][keyY];
            }
          });
        }
      });

      // 先生写真 + 先生写真下テキスト01 + 先生写真下テキスト02 + 見出し + 本文
      modalData = '<div class="l-wrapInner"><div class="c-modal_media"><div class="c-modal_mediaPic"><div class="c-modal_mediaPicInner"><img src="' + modalTeacherImg + '" alt="" /></div><p class="c-modal_mediaCap c-textType02--bold u-color_text--white u-lhType02 u-tac">' + modalName01 + '</p><p class="c-modal_mediaCap c-textType02--bold u-color_text--white u-lhType02 u-tac">' + modalName02 + '</p></div><div class="c-modal_mediaBox"><p class="c-modal_mediaHeading c-headingType03 u-lhType01 u-color_text--white">' + modalTitle + '</p><p class="c-textType02--medium u-lhType02 u-color_text--white">' + modalText + '</p></div></div><div class="c-modal_btn"><a href="' + modalProfLink + '" class="c-btnType02"><p class="c-btn_text">プロフィールを見る</p><span class="c-btn_arrow--black"></span></a></div></div>';

      // 実績画像が一つでもあれば実績をmodalDataに追加
      if (modalArtImg01 != '') {
        if (modalArtImg02 == '') {
          modalData = modalData + '<dl class="c-modal_ac"><dt class="c-modal_acLead c-headingType05 u-color_text--green u-tac">実績</dt><dd class="c-modal_acInfo"><div class="l-wrapInner"><ul class="c-modal_list--modal"><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg01 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap01 + '</p></div></div></div></li></ul></div></dd></dl>';
        } else if (modalArtImg02 != '' && modalArtImg03 != '') {
          modalData = modalData + '<dl class="c-modal_ac"><dt class="c-modal_acLead c-headingType05 u-color_text--green u-tac">実績</dt><dd class="c-modal_acInfo"><div class="l-wrapInner"><ul class="c-modal_list--modal"><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg01 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap01 + '</p></div></div></div></li><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg02 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap02 + '</p></div></div></div></li><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg03 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap03 + '</p></div></div></div></li></ul></div></dd></dl>';
        } else {
          modalData = modalData + '<dl class="c-modal_ac"><dt class="c-modal_acLead c-headingType05 u-color_text--green u-tac">実績</dt><dd class="c-modal_acInfo"><div class="l-wrapInner"><ul class="c-modal_list--modal"><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg01 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap01 + '</p></div></div></div></li><li class="c-modal_listItem"><div class="c-card"><div class="c-card_pic">' + modalArtImg02 + '</div><div class="c-card_cont"><div class="c-card_inner"><p class="c-card_text u-color_text--white">' + modalCap02 + '</p></div></div></div></li></ul></div></dd></dl>';
        }
      }

      // modal内に先生のプロフィールデータを追加
      modalTeacher.innerHTML = modalData;

      // モーダル各要素を表示
      bodyEle.classList.add('is-fixed');
      modalTeacherOverlay.classList.add('is-view');
      modalTeacherEle.classList.add('is-view');
    });
  });

  if (document.querySelector('.js-modalTeacherClose')) {
    modalTeacherClose.addEventListener('click', () => {
      // モーダル反映用に使用した変数のリセット
      modalName01 = '';
      modalName02 = '';
      modalTitle = '';
      modalText = '';
      modalTeacherImg = '';
      modalArtImg01 = '';
      modalArtImg02 = '';
      modalArtImg03 = '';
      modalCap01 = '';
      modalCap02 = '';
      modalCap03 = '';
      modalData = '';
      modalProfLink = '';

      // innerHTMLで追加したDOMを削除
      while (modalTeacher.firstChild) {
        modalTeacher.removeChild(modalTeacher.firstChild);
      }

      // モーダル各要素を非表示
      bodyEle.classList.remove('is-fixed');
      modalTeacherOverlay.classList.remove('is-view');
      modalTeacherEle.classList.remove('is-view');
    });
  }
}
