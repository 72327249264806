export function hamburger() {
  const bodyEle = document.querySelector('body');
  const headerEle = document.querySelector('.js-headerEle');
  const hamburgerEle = document.querySelector('.js-hamburgerEle');

  const headerIndexEle = document.querySelector('.js-headerIndex');
  const headerOtherEle = document.querySelector('.js-headerOther');
  const headerMenuEle = document.querySelectorAll('.js-headerMenu');
  const headerMenuEleList = Array.prototype.slice.call(headerMenuEle, 0);
  const headerOtherContEle = document.querySelectorAll('.js-headerOtherCont');
  const headerOtherContEleList = Array.prototype.slice.call(headerOtherContEle, 0);
  const headerMenuItemEle = document.querySelectorAll('.js-headerMenuItem');
  const headerMenuItemEleList = Array.prototype.slice.call(headerMenuItemEle, 0);

  // sp時のハンバーガーメニューのページ外アンカーリンク
  const headerMenuSubItemEle = document.querySelectorAll('.js-headerMenuSubItem');
  const headerMenuSubItemEleList = Array.prototype.slice.call(headerMenuSubItemEle, 0);

  /** 目次メニュー選択時 */
  headerMenuEleList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      const boxId = targetBox.getAttribute('id');
      for (let i = 0; i < headerOtherContEleList.length; i++) {
        if (headerOtherContEleList[i].dataset.box == boxId) {
          headerOtherContEleList[i].classList.add('is-view');
        }
      }

      headerIndexEle.classList.add('is-animated');
      headerOtherEle.classList.add('is-animated');
      setTimeout(() => {
        headerIndexEle.classList.add('is-hide');
      }, 150);
    });
  });

  /** 他メニュー選択時 */
  headerMenuItemEleList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      for (let i = 0; i < headerOtherContEleList.length; i++) {
        headerOtherContEleList[i].classList.remove('is-view');
      }

      headerIndexEle.classList.remove('is-animated');
      headerOtherEle.classList.remove('is-animated');
      setTimeout(() => {
        headerIndexEle.classList.remove('is-hide');
      }, 150);
    });
  });

  /** ハンバーガーメニュー選択時 */
  hamburgerEle.addEventListener('click', () => {
    if (hamburgerEle.classList.contains('is-active')) {
      hamburgerReset();
    } else {
      hamburgerEle.classList.add('is-active');
      hamburgerEle.classList.remove('is-actived');
      headerEle.classList.add('is-actived');
      bodyEle.classList.add('is-fixed');
    }
  });

  /** ハンバーガーメニューのページ外アンカーリンク選択時 */
  headerMenuSubItemEleList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      if (location.hash) {
        hamburgerReset();
      }
    });
  });

  function hamburgerReset() {
    hamburgerEle.classList.remove('is-active');
    hamburgerEle.classList.add('is-actived');
    headerEle.classList.remove('is-actived');
    bodyEle.classList.remove('is-fixed');

    /** ハンバーガーメニュー内のリセット処理 */
    for (let i = 0; i < headerOtherContEleList.length; i++) {
      headerOtherContEleList[i].classList.remove('is-view');
    }

    headerIndexEle.classList.remove('is-animated');
    headerOtherEle.classList.remove('is-animated');
    headerIndexEle.classList.remove('is-hide');
  }
}
