/**
 * アコーディオンで使用するjsです。
 * dt要素を押すと、dt、dtと隣合わせのddに'is-open'classが付与されて、
 * アコーディオンが開閉します。
 */

export function acAction() {
  if (document.querySelector('.js-ac')) {
    const dtEle = document.querySelectorAll('.js-ac dt');
    dtEle.forEach((dt) => {
      dt.addEventListener('click', () => {
        dt.classList.toggle('is-open');
        dt.nextElementSibling.classList.toggle('is-open');
      });
    });
  }
}
