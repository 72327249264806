/**
 * ページ内リンクなどのスムーススクロール用jsになります。
 * header: '#header'は、headerの高さ分を考慮してアンカーリンクの処理を実行する為の設定です。
 * speedAsDurationがfalseだと、伸びるようなeasingになってしまうので、trueに設定して等速にしています。
 * updateURLがtrueだと、ページ内遷移をしたさいurlに#〇〇が付与されます。falseだとurlには何もつきません。（なお、別ページの指定箇所へ飛ぶ場合はurlに#〇〇が付いてしまうみたいです）
 */

import SmoothScroll from 'smooth-scroll';

export function smooth() {
  window.addEventListener('load', () => {
    const scroll = new SmoothScroll('a[href*="#"]', {
      header: '#header',
      speed: 300,
      speedAsDuration: true,
      updateURL: false,
    });

    /** ページ外アンカーリンクの場合の処理 */
    if (location.hash) {
      const headerEle = document.getElementById('header');
      const headerHeight = Number(headerEle.clientHeight);
      const headerEleRect = headerEle.getBoundingClientRect();
      const headerElePos = window.pageYOffset + headerEleRect.top;

      const diff = headerElePos - headerHeight;
      scrollTo(0, diff);
    }
  });
}
