/**
 * scroll、resizeなど連続した処理の中で判別する処理の負荷軽減、および処理実行の為のjsです。
 * lodashのthrottleを使用することで負荷軽減を行っています。
 */

import { throttle } from 'lodash';
import { countCircle, countBar } from './count';

export function lodashAction() {
  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        countCircleView();
        countBarView();
        scrollView();
        return false;
      },
      10,
      { trailing: true, leading: true }
    )
  );

  window.addEventListener('load', () => {
    countCircleView();
    countBarView();
    scrollView();
  });

  function countCircleView() {
    /**
     * 円グラフの要素にis-viewが付与されていたら、
     * アニメーション発火用classを付与する処理が実行されます。
     */
    if (document.querySelector('.js-graphCircle')) {
      const graphCircleEle = document.querySelector('.js-graphCircle');

      if (graphCircleEle.classList.contains('is-view')) {
        countCircle();
      }
    }
  }

  function countBarView() {
    /**
     * 棒グラフの要素にis-viewが付与されていたら、
     * アニメーション発火用classを付与する処理が実行されます。
     */
    if (document.querySelector('.js-graphBar')) {
      const graphBarEle = document.querySelector('.js-graphBar');

      if (graphBarEle.classList.contains('is-view')) {
        setTimeout(() => {
          countBar();
        }, 200);
      }
    }
  }

  function scrollView() {
    /**
     * ページトップに戻るボタンの表示、非表示の判定をしています。
     * scroll量がページ最上部より115以上、以下になると
     * ページトップに戻るボタンが表示、非表示されます。
     */

    const scroll = window.pageYOffset;

    if (document.querySelector('.js-pageTop')) {
      // スクロール量がkvの高さを超えたらページトップボタンを表示
      const pageTopEle = document.querySelector('.js-pageTop');
      if (scroll >= 115) {
        pageTopEle.classList.add('is-view');
      } else {
        pageTopEle.classList.remove('is-view');
      }
    }

    if (document.querySelector('.js-admissionBtn')) {
      // スクロール量がkvの高さを超えたらボタンを表示
      const pageTopEle = document.querySelector('.js-admissionBtn');
      if (scroll >= 115) {
        pageTopEle.classList.add('is-view');
      } else {
        pageTopEle.classList.remove('is-view');
      }
    }
  }
}
