export function modalAction() {
  if (document.querySelector('.js-modalEle')) {
    const body = document.querySelector('body');
    const modalEle = document.querySelector('.js-modalEle');
    const modalTableEle = document.querySelector('.js-modalTableEle');
    const modalMovieEle = document.querySelector('.js-modalMovieEle');
    const modalTrigger = document.querySelectorAll('.js-modalTrigger');
    const modalTriggerList = Array.prototype.slice.call(modalTrigger, 0);
    const modalFigure = document.querySelectorAll('.js-modalFigure');
    const modalFigureList = Array.prototype.slice.call(modalFigure, 0);
    const modalMovie = document.querySelectorAll('.js-modalMovie');
    const modalMovieList = Array.prototype.slice.call(modalMovie, 0);
    const modalFigurePanel = document.querySelectorAll('.js-modalFigurePanel');
    const modalFigurePanelList = Array.prototype.slice.call(modalFigurePanel, 0);
    const modalTable = document.querySelectorAll('.js-modalTable');
    const modalTableList = Array.prototype.slice.call(modalTable, 0);
    const modalFigureSet = document.querySelector('.js-modalFigureSet');
    const modalFigureSetText = document.querySelector('.js-modalFigureSetText');
    const modalOverlay = document.querySelector('.js-modalOverlay');
    const modalClose = document.querySelector('.js-modalClose');
    const modalTableClose = document.querySelector('.js-modalTableClose');
    const modalMovieClose = document.querySelector('.js-modalMovieClose');

    let selectImg = '';

    /** modal open */
    modalTriggerList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        body.classList.add('is-fixed');
        modalOverlay.classList.add('is-view');
        modalEle.classList.add('is-view');
      });
    });

    /** 選択した画像をモーダルで表示（.c-card） */
    modalFigureList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        if (targetBox.firstElementChild.firstElementChild.hasAttribute('data-modalimg')) {
          selectImg = targetBox.firstElementChild.firstElementChild.dataset.modalimg;
        } else {
          selectImg = targetBox.firstElementChild.firstElementChild.getAttribute('src');
        }
        const selectImgText = targetBox.nextElementSibling.firstElementChild.lastElementChild.innerHTML;
        modalFigureSet.setAttribute('src', selectImg);
        modalFigureSetText.innerHTML = selectImgText;

        body.classList.add('is-fixed');
        modalOverlay.classList.add('is-view');
        modalEle.classList.add('is-view');
      });
    });

    /** 選択した画像をモーダルで表示（.c-panelCard */
    modalFigurePanelList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        selectImg = targetBox.firstElementChild.firstElementChild.firstElementChild.getAttribute('src');
        const selectImgText = targetBox.firstElementChild.lastElementChild.firstElementChild.firstElementChild.textContent;
        modalFigureSet.setAttribute('src', selectImg);
        modalFigureSetText.textContent = selectImgText;

        body.classList.add('is-fixed');
        modalOverlay.classList.add('is-view');
        modalEle.classList.add('is-view');
      });
    });

    /** 時間割をモーダルで表示 */
    modalTableList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        body.classList.add('is-fixed');
        modalOverlay.classList.add('is-view');
        modalTableEle.classList.add('is-view');
      });
    });

    /** youtubeをiframeで表示 */
    modalMovieList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        const movieCont = document.createElement('div');
        movieCont.className = 'c-modal_movie';

        if (targetBox.dataset.youtube) {
          const movieId = targetBox.getAttribute('data-youtube');
          movieCont.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + movieId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
        if (targetBox.dataset.vimeo) {
          const movieId = targetBox.getAttribute('data-vimeo');
          movieCont.innerHTML = '<iframe src="https://player.vimeo.com/video/' + movieId + '" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>';
        }
        if (targetBox.dataset.video) {
          const movieFile = targetBox.getAttribute('data-video');
          movieCont.innerHTML = '<video src="/assets/movie/' + movieFile + '" type="mp4" controls muted playsinline></video>';
        }

        modalMovieEle.firstElementChild.firstElementChild.appendChild(movieCont);

        body.classList.add('is-fixed');
        modalOverlay.classList.add('is-view');
        modalMovieEle.classList.add('is-view');
      });
    });

    /** modal close */
    if (document.querySelector('.js-modalClose')) {
      modalClose.addEventListener('click', () => {
        body.classList.remove('is-fixed');
        modalOverlay.classList.remove('is-view');
        modalEle.classList.remove('is-view');
        modalFigureSet;
        modalFigureSet.setAttribute('src', '');
      });
    }

    if (document.querySelector('.js-modalMovieClose')) {
      modalMovieClose.addEventListener('click', () => {
        const modalMovie = document.querySelector('.c-modal_movie');
        modalMovieEle.firstElementChild.firstElementChild.removeChild(modalMovie);

        body.classList.remove('is-fixed');
        modalOverlay.classList.remove('is-view');
        modalMovieEle.classList.remove('is-view');
      });
    }

    if (document.querySelector('.js-modalTableClose')) {
      modalTableClose.addEventListener('click', () => {
        body.classList.remove('is-fixed');
        modalOverlay.classList.remove('is-view');
        modalTableEle.classList.remove('is-view');
      });
    }
  }
}
