export function countCircle() {
  /**
   * 円グラフのアニメーション用関数です。
   * 処理順は
   * 1.円グラフの対象要素全取得
   * 2.取得した要素に対して、
   *   2-1.data属性の取得
   *   2-2.取得した要素の最初の要素のテキストを取得（0スタート）
   *   2-3.data値と一致するまで加算 → 0 ~ data値
   * 3.data値とカウント数が一致すれば処理終了
   */

  const countCircleEle = document.querySelectorAll('.js-countCircleEle');
  const countCircleEleList = Array.prototype.slice.call(countCircleEle, 0);

  countCircleEleList.forEach((targetBox) => {
    const dataNum = Number(targetBox.getAttribute('data-num'));
    let numTarget = targetBox.children.item(0);
    let numText = Number(numTarget.textContent);

    let timerId = setInterval(() => {
      if (numText === dataNum) {
        clearInterval(timerId);
      } else {
        numText++;
        numTarget.textContent = numText;
      }
    }, 10);
  });
}

export function countBar() {
  /**
   * 棒グラフのアニメーション用関数です。
   * 処理順は
   * 1.棒グラフの対象要素全取得
   * 2.取得した要素に対して、
   *   2-1.data属性の取得
   *   2-2.取得した要素の2番目の要素のテキストを取得（0スタート）
   *   2-3.data値と一致するまで加算 → 0 ~ data値
   * 3.data値とカウント数が一致すれば処理終了
   */
  const countBarEle = document.querySelectorAll('.js-countBarEle');
  const countBarEleList = Array.prototype.slice.call(countBarEle, 0);

  countBarEleList.forEach((targetBox) => {
    const dataNum = Number(targetBox.getAttribute('data-num'));
    let numTarget = targetBox.children.item(1);
    let numText = Number(numTarget.textContent);

    let timerId = setInterval(() => {
      if (numText === dataNum) {
        clearInterval(timerId);
      } else {
        numText++;
        numTarget.textContent = numText;
      }
    }, 15);
  });
}
