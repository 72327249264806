/**
 * スライダー用のjsです。
 */

import Swiper, { Navigation, Autoplay } from 'swiper';
import { modalTeacher } from './modalTeacher';

export function slider() {
  const mediaSlider = new Swiper('#teacherMedia', {
    modules: [Navigation, Autoplay],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    centeredSlides: true,
    initialSlide: 1,
    touchRatio: 0.1,
    breakpoints: {
      768: {
        slidesPerView: 2.5,
      },
      1200: {
        slidesPerView: 3.5,
      },
    },
  });
  const designSlider = new Swiper('#teacherDesign', {
    modules: [Navigation, Autoplay],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    centeredSlides: true,
    initialSlide: 1,
    touchRatio: 0.1,
    breakpoints: {
      768: {
        slidesPerView: 2.5,
      },
      1200: {
        slidesPerView: 3.5,
      },
    },
  });

  //IntersectionObserver のコールバック関数
  function callbackMedia(entries) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= 1) {
        // 対象の要素（スライダー）がすべて見えたら再生
        mediaSlider.autoplay.start();
      } else if (entry.intersectionRatio === 0) {
        // 対象の要素（スライダー）がすべて見えなくなったら停止
        mediaSlider.autoplay.stop();
        //mediaSlider.slideToLoop(0); //先頭のスライドへ
      }
    });
  }
  function callbackDesign(entries) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= 1) {
        // 対象の要素（スライダー）がすべて見えたら再生
        designSlider.autoplay.start();
      } else if (entry.intersectionRatio === 0) {
        // 対象の要素（スライダー）がすべて見えなくなったら停止
        designSlider.autoplay.stop();
        //mediaSlider.slideToLoop(0); //先頭のスライドへ
      }
    });
  }

  //IntersectionObserver のオプション
  const ioOptions = {
    //全て見えるときと全て見えないときを検知するための閾値
    threshold: [0, 1],
    //監視領域のオフセット（見える手前100pxで表示する場合）
    //rootMargin: '100px',
  };
  //オブザーバーを生成
  const observerMedia = new IntersectionObserver(callbackMedia, ioOptions);
  const observerDesign = new IntersectionObserver(callbackDesign, ioOptions);
  //監視対象の領域（スライダー）を取得
  const targetMedia = document.getElementById('teacherMedia');
  const targetDesign = document.getElementById('teacherDesign');
  //オブザーバーに監視対象の領域（スライダー）を指定
  if (document.getElementById('teacherMedia')) {
    observerMedia.observe(targetMedia);
  }
  if (document.getElementById('teacherDesign')) {
    observerDesign.observe(targetDesign);
  }
}

export function sliderSpOnly() {
  let swiperMain, swiperbool, swiperWrapLength, sliderWrap, sliderMain;
  let sliderBox = [];
  const breakPoint = 767;

  window.addEventListener(
    'load',
    () => {
      if (breakPoint < window.innerWidth) {
        swiperbool = false;
      } else {
        createSwiper();
        swiperbool = true;
      }
    },
    false
  );

  window.addEventListener(
    'resize',
    () => {
      if (breakPoint < window.innerWidth && swiperbool) {
        // PCレイアウト切り替え時にはslideBox内に格納された各swiperを破棄
        for (let i = 0; i < sliderBox.length; i++) {
          sliderBox[i].destroy(true, true);
          sliderWrap[i].classList.replace('js-sliderSpWrap0' + (i + 1), 'js-sliderSpWrap');
          sliderMain[i].classList.replace('js-sliderSp0' + (i + 1), 'js-sliderSp');
        }

        sliderBox = [];
        swiperbool = false;
      } else if (breakPoint >= window.innerWidth && !swiperbool) {
        createSwiper();
        swiperbool = true;
      }
    },
    false
  );

  function createSwiper() {
    //ラッパー要素を指定
    sliderWrap = document.querySelectorAll('.js-sliderSpWrap');
    //メインのスライダーを指定
    sliderMain = document.querySelectorAll('.js-sliderSp');

    swiperWrapLength = sliderWrap.length;
    //js-sliderSpWrapの数だけ繰り返す
    for (let i = 0; i < sliderWrap.length; i++) {
      //.js-sliderSpWrap、.js-sliderSpに01から始まる連番を振る
      sliderWrap[i].classList.replace('js-sliderSpWrap', 'js-sliderSpWrap0' + (i + 1));
      sliderMain[i].classList.replace('js-sliderSp', 'js-sliderSp0' + (i + 1));

      //メインのスライダー呼び出し&オプション指定
      swiperMain = new Swiper('.js-sliderSp0' + (i + 1), {
        modules: [Navigation, Autoplay],
        navigation: {
          nextEl: '.swiper-button-next.js-swiperSpNext',
          prevEl: '.swiper-button-prev.js-swiperSpPrev',
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 40,
        loop: true,
        centeredSlides: true,
        initialSlide: 1,
      });

      sliderBox[i] = swiperMain;
    }
  }
}
