/**
 * スクロール中、対象要素が画面の指定位置に到達した際に処理を行うjsです。
 * 行っていることは、Allで取得した要素が設定位置まで到達した際に、アニメーション発火用classを付与しているだけです。
 * 各設定としては、
 * triggerHookが到達位置の設定となっていて
 *   onLeave → 対象要素が画面上橋に来た時
 *   onCenter → 対象要素が中央に来た時
 *   onEnter → 対象要素が画面下端に来た時（下にスクロールしていて、少しでも下に対象要素が表示されていたら発火）
 * という位置設定になります。
 * reverseがtrueの場合、スクロール中に一度発火した処理を、再度同タイミングで処理を発火させる状態になります。（falseだと処理は初回のみになります）
 * offsetは、onLeaveなどの位置設定から〇〇離した距離に到達したら...という微調整の設定値なります。
 */

import ScrollMagic from 'scrollmagic';

export function scrollMagic() {
  let controller = new ScrollMagic.Controller();

  /** scrollView */
  let fadeIn = document.querySelectorAll('.js-scrollView');
  for (let i = 0; i < fadeIn.length; i++) {
    let tag = fadeIn[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onCenter',
      reverse: false,
      offset: -300,
    })
      .setClassToggle(tag, 'is-view')
      .addTo(controller);
  }
  /** end scrollView */

  /** scrollViewCatch（学科見出し） */
  let fadeInCatch = document.querySelectorAll('.js-scrollViewCatch');
  for (let i = 0; i < fadeInCatch.length; i++) {
    let tagCatch = fadeInCatch[i];

    new ScrollMagic.Scene({
      triggerElement: tagCatch,
      triggerHook: 'onCenter',
      reverse: false,
      offset: -500,
    })
      .setClassToggle(tagCatch, 'is-view')
      .addTo(controller);
  }
  /** end scrollViewCatch */

  /** pageTop */
  let fixedBtn = document.querySelectorAll('.js-scrollPageTop');
  for (let i = 0; i < fixedBtn.length; i++) {
    let tagFixedBtn = fixedBtn[i];

    new ScrollMagic.Scene({
      triggerElement: tagFixedBtn,
      triggerHook: 'onEnter',
      reverse: true,
      offset: 0,
    })
      .setClassToggle(tagFixedBtn, 'is-fixed')
      .addTo(controller);
  }
  /** end pageTop */

  /** admissionFixedBtn */
  let admissionFixedBtn = document.querySelectorAll('.js-admissionBtn');
  let btnFooter = document.querySelectorAll('.l-footer');
  for (let i = 0; i < admissionFixedBtn.length; i++) {
    let tagAdmissionFixedBtn = admissionFixedBtn[i];

    new ScrollMagic.Scene({
      triggerElement: btnFooter,
      triggerHook: 'onEnter',
      reverse: true,
      offset: 0,
    })
      .setClassToggle(tagAdmissionFixedBtn, 'is-fixed')
      .addTo(controller);
  }
  /** end admissionFixedBtn */
}
