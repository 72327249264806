import { acAction } from './modules/acAction';
import { hamburger } from './modules/hamburger';
import { lodashAction } from './modules/lodashAction';
import { modalAction } from './modules/modalAction';
import { modalTeacher } from './modules/modalTeacher';
import { scrollMagic } from './modules/scrollMagic';
import { slider, sliderSpOnly } from './modules/slider';
import { smooth } from './modules/smooth';
import { tabChange } from './modules/tabChange';
lodashAction();
smooth();

slider();
sliderSpOnly();
hamburger();
scrollMagic();
tabChange();
acAction();
modalAction();
modalTeacher();
